<script>
	import '../globals.css';

	import '@fontsource/patua-one';
	import '@fontsource/atkinson-hyperlegible';
	import '@fontsource/atkinson-hyperlegible/latin-700.css';

	import atkinsonhyperlegibleWoff2 from '@fontsource/atkinson-hyperlegible/files/atkinson-hyperlegible-latin-400-normal.woff2?url';
	import atkinsonhyperlegibleBoldWoff2 from '@fontsource/atkinson-hyperlegible/files/atkinson-hyperlegible-latin-700-normal.woff2?url';
	import patuaoneWoff2 from '@fontsource/patua-one/files/patua-one-latin-400-normal.woff2?url';

	import { setContext } from 'svelte';
	import { writable } from 'svelte/store';

	const quotes = writable([]);
	setContext('quotes', quotes);
</script>

<svelte:head>
	<link
		rel="preload"
		as="font"
		type="font/woff2"
		href={atkinsonhyperlegibleWoff2}
		crossorigin="anonymous"
	/>
	<link
		rel="preload"
		as="font"
		type="font/woff2"
		href={atkinsonhyperlegibleBoldWoff2}
		crossorigin="anonymous"
	/>
	<link rel="preload" as="font" type="font/woff2" href={patuaoneWoff2} crossorigin="anonymous" />
	<title>Motivational Messages ~ Tygerberg Campus Health Day 2024</title>
	<meta
		name="description"
		content="See your own motivational message and pick which message the next person gets!"
	/>
</svelte:head>

<slot />
