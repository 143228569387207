export const prerender = true;
export const trailingSlash = 'always';
import { browser } from '$app/environment';

import initAnalytics from '../util/plausible.js';

initAnalytics('https://analytics.frans.app/api/event', 'motivationalcards.frans.app');

if (browser) {
	console.log(
		"Hi there! Glad that you're interested in how it works. It's hosted on Cloudflare Pages & Vultr. Cloudflare routes some traffic through the Miami region for some reason, leading to high latency for some users. The UI is built in SvelteKit and the backend in Node. Hopefully you're not here because something broke! Message us on Instagram if you have any questions! https://instagram.com/frans.vergeest & https://instagram.com/nivivana"
	);
}
